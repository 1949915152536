@import "/src/styles/mixin";

.columns {
  display: flex;
  align-items: flex-start;
  gap: 60px;
  padding-bottom: 100px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }

  .delivery {
    width: 100%;
    z-index: 4;
  }
}
